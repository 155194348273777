<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <label class="title">Who we are:
          </label>
          <b-col
            cols="10"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="`https://lpcentre.com/${form.who_we_are_image}`"
                    height="150"
                    width="200"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="form.who_we_are_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="form.who_we_are_alter"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="10">
            <b-form-group

              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <quill-editor
                  ref="whoWeEditor"
                  v-model="form.who_we_are_value"
                 :options="getEditorOptions('whoWeEditor')"
                />
               
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <label class="title">Our goal:
        </label>
        <b-row>

          <b-col
            cols="10"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="`https://lpcentre.com/${form.our_goal_image}`"
                    height="150"
                    width="200"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="form.our_goal_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="form.our_goal_alter"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="10">
            <b-form-group

              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                  <quill-editor
                  ref="whogoalEditor"
                  v-model="form.who_we_are_value"
                :options="getEditorOptions('whogoalEditor')"
                />
               
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row><b-row>
          <label class="title">What we do1:
          </label>
          <b-col
            cols="10"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE3"
                    :src="`https://lpcentre.com/${form.what_we_do1_image}`"
                    height="150"
                    width="200"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE3"
                          v-model="form.what_we_do1_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="form.what_we_do1_alter"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="10">
            <b-form-group

              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <quill-editor
                  ref="whowe_do1Editor"
                  v-model="form.who_we_are_value"
                
                :options="getEditorOptions('whowe_do1Editor')"
                />
                
              
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row><b-row>
          <label class="title">What we do2:
          </label>
          <b-col
            cols="10"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE4"
                    :src="`https://lpcentre.com/${form.what_we_do2_image}`"
                    height="150"
                    width="200"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE4"
                          v-model="form.what_we_do2_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab4"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="form.what_we_do2_alter"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="10">
            <b-form-group

              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
               
   <quill-editor
                  ref="whowe_do2Editor"
                  v-model="form.who_we_are_value"
                  :options="getEditorOptions('whowe_do2Editor')"
                />
              
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <label class="title">What we do3:
          </label>
          <b-col
            cols="10"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE5"
                    :src="`https://lpcentre.com/${form.what_we_do3_image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE5"
                          v-model="form.what_we_do3_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab5"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="form.what_we_do3_alter"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="10">
            <b-form-group

              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                  <quill-editor
                  ref="whowwe_do3ditor"
                  v-model="form.who_we_are_value"
                  :options="getEditorOptions('whowwe_do3ditor')"
                />
             
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row><b-row>
          <label class="title">What we do4:
          </label>
          <b-col
            cols="10"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE6"
                    :src="`https://lpcentre.com/${form.what_we_do4_image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE6"
                          v-model="form.what_we_do4_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab6"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="form.what_we_do4_alter"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="10">
            <b-form-group

              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                  <quill-editor
                  ref="whowhat_we_do4_valueditor"
                  v-model="form.who_we_are_value"
             :options="getEditorOptions('whowhat_we_do4_valueditor')"
                />
               
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="10"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
        <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import Editor from '@tinymce/tinymce-vue'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive,getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)


export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
     const { proxy } = getCurrentInstance()
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const refInputE3 = ref(null)
    const refInputE4 = ref(null)
    const refPreviewE4 = ref(null)
    const refPreviewE3 = ref(null)
    const refInputE5 = ref(null)
    const refInputE6 = ref(null)
    const refPreviewE5 = ref(null)
    const refPreviewE6 = ref(null)
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const categoriesList = ref([])
    const form = ref({
      who_we_are_value: '',
      who_we_are_id: '',
      who_we_are_alter: '',
      who_we_are_image: '',
      our_goal_value: '',
      our_goal_id: '',
      our_goal_image: '',
      our_goal_alter: '',
      what_we_do1_value: '',
      what_we_do1_id: '',
      what_we_do1_image: '',
      what_we_do1_alter: '',
      what_we_do2_value: '',
      what_we_do2_id: '',
      what_we_do2_image: '',
      what_we_do2_alter: '',
      what_we_do3_value: '',
      what_we_do3_id: '',
      what_we_do3_image: '',
      what_we_do3_alter: '',
      what_we_do4_value: '',
      what_we_do4_id: '',
      what_we_do4_image: '',
      what_we_do4_alter: '',

    })
    const addCourseForm = reactive(form)
    const data = ['who_we_are',
      'our_goal',
      'what_we_do1',
      'what_we_do2',
      'what_we_do3',
      'what_we_do4']
    const formData = new FormData()
    formData.append('data', JSON.stringify(data))
    store.dispatch('setting/GetSettingArray', formData).then(response => {
      // form.value=response.data
      form.value.who_we_are_value = response.data[0].value
      form.value.who_we_are_alter = response.data[0].alter_img
      form.value.who_we_are_id = response.data[0].id
      form.value.who_we_are_image = response.data[0].img
      form.value.our_goal_alter = response.data[1].alter_img
      form.value.our_goal_value = response.data[1].value
      form.value.our_goal_id = response.data[1].id
      form.value.our_goal_image = response.data[1].img
      form.value.what_we_do1_alter = response.data[2].alter_img
      form.value.what_we_do1_value = response.data[2].value
      form.value.what_we_do1_id = response.data[2].id
      form.value.what_we_do1_image = response.data[2].img
      form.value.what_we_do2_alter = response.data[3].alter_img
      form.value.what_we_do2_value = response.data[3].value
      form.value.what_we_do2_id = response.data[3].id
      form.value.what_we_do2_image = response.data[3].img
      form.value.what_we_do3_alter = response.data[4].alter_img
      form.value.what_we_do3_value = response.data[4].value
      form.value.what_we_do3_id = response.data[4].id
      form.value.what_we_do3_image = response.data[4].img
      form.value.what_we_do4_alter = response.data[5].alter_img
      form.value.what_we_do4_value = response.data[5].value
      form.value.what_we_do4_id = response.data[5].id
      form.value.what_we_do4_image = response.data[5].img
    })

    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      const data = [{
        name: 'who_we_are',
        value: form.value.who_we_are_value,
        alter_img: form.value.who_we_are_alter,
        image: form.value.who_we_are_image,
      },
      {
        name: 'our_goal',
        value: form.value.our_goal_value,
        alter_img: form.value.our_goal_alter,
        image: form.value.our_goal_image,
      }, {
        name: 'what_we_do1',
        value: form.value.what_we_do1_value,
        alter_img: form.value.what_we_do1_alter,
        image: form.value.what_we_do1_image,
      },
      {
        name: 'what_we_do2',
        value: form.value.what_we_do2_value,
        alter_img: form.value.what_we_do2_alter,
        image: form.value.what_we_do2_image,
      },
      {
        name: 'what_we_do3',
        value: form.value.what_we_do3_value,
        alter_img: form.value.what_we_do3_alter,
        image: form.value.what_we_do3_image,
      },
      {
        name: 'what_we_do4',
        value: form.value.what_we_do4_value,
        alter_img: form.value.what_we_do4_alter,
        image: form.value.what_we_do4_image,
      },

      ]

      formData.append('data', JSON.stringify(data))

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          store
            .dispatch('setting/updateSettingArray', formData)
            .then(response => {
              Vue.swal({
                title: 'Updated',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
     const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
      console.log(currentEditor)
      const range = editorSelection || editor.getSelection()
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties

      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-properties-modal')
    }
    const { inputImageRendererTab3 } = useInputImageRenderer(
      refInputE3,
      base64 => {
        refPreviewE3.value.src = base64
      },
    )
    const { inputImageRendererTab4 } = useInputImageRenderer(
      refInputE4,
      base64 => {
        refPreviewE4.value.src = base64
      },
    )
    const { inputImageRendererTab5 } = useInputImageRenderer(
      refInputE5,
      base64 => {
        refPreviewE5.value.src = base64
      },
    )
    const { inputImageRendererTab6 } = useInputImageRenderer(
      refInputE6,
      base64 => {
        refPreviewE6.value.src = base64
      },
    )
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
     const getEditorOptions = (editorRef) => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image'],
          ],
          handlers: {
            image: () => imageHandler(editorRef),
              link: () => linkHandler(editorRef)
          },
        },
      },
    })
const linkHandler = editorRef => {
  const range = proxy.$refs[editorRef].quill.getSelection()
  if (range) {
    const url = prompt('Enter the URL')
    if (url) {
      const openInNewTab = confirm('Open in new tab?')
      const selectedText = proxy.$refs[editorRef].quill.getText(range)
      const targetAttribute = openInNewTab ? ' target="oooooooooo" rel="noopener noreferrer"' : ''
      const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`

      // Remove the selected text
      proxy.$refs[editorRef].quill.deleteText(range.index, range.length)

      // Insert the link at the original selection index
      proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
    }
  }
}
    return {
    
      refInputEl,

      refPreviewEl,
      refInputE2,
      refPreviewE2,
      refInputE3,
      refPreviewE3,
      refInputE4,
      refPreviewE4,
      refInputE5,
      refPreviewE5,
      refInputE6,
      refPreviewE6,
      inputImageRendererTab,
      inputImageRendererTab6,
      inputImageRendererTab5,
      inputImageRendererTab4,
      inputImageRendererTab3,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      categoriesList,
      form,
imageHandler,
   linkHandler,
   getEditorOptions,
  insertImage,
      imageProperties,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    Editor,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,

    BForm,
  
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
   
    quillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
label.title {
    font-size: 25px;
}
.border.rounded.p-2 {
   margin: 1px !important;
}
.p-2{
  margin: 0px !important;
}
</style>
